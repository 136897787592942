import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import '../css/HeroSection.css';

const slideImages = [
  {
    src: `${process.env.PUBLIC_URL}/img/related-post/related-post-3.jpg`,
    alt: 'Slide 3',
    link: '/circ',
    text: 'CIRC 2025',
  },
  {
    src: `${process.env.PUBLIC_URL}/img/related-post/related-post-1.jpg`,
    alt: 'Slide 1',
    link: '/exhibition',
    text: 'Medical Exhibition',
  },
  {
    src: `${process.env.PUBLIC_URL}/img/related-post/related-post-2.jpg`,
    alt: 'Slide 2',
    link: 'https://eu.jotform.com/form/241273222563349#preview',
    text: 'Submissão de Trabalhos',
  },

  {
    src: `${process.env.PUBLIC_URL}/img/related-post/related-post-4.jpg`,
    alt: 'Slide 4',
    link: '/schedule',
    text: 'Programa Oficial 2025',
  },
];

function HeroSection() {
  return (
    <section className="hero-section">
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        loop={true}
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {slideImages.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="slide-content">
              <img
                src={slide.src}
                alt={slide.alt}
                className="slide-image"
                style={{ width: '100%' }}
              />
              <div className="text-overlay">
                <h4>
                  <Link to={slide.link}>{slide.text}</Link>
                </h4>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default HeroSection;
