// src/components/js/Sponsors.js
import React from 'react';
import '../css/Sponsors.css';

const sponsors = [
  { name: 'Sponsor 1', logo: '/img/partner-logo/logo-1.png' },
  { name: 'Sponsor 3', logo: '/img/partner-logo/logo-3.png' },
  { name: 'Sponsor 4', logo: '/img/partner-logo/logo-4.png' },
  { name: 'Sponsor 5', logo: '/img/partner-logo/logo-5.png' },
  { name: 'Sponsor 6', logo: '/img/partner-logo/logo-6.png' },
  { name: 'Sponsor 7', logo: '/img/partner-logo/logo-7.png' },
  { name: 'Sponsor 8', logo: '/img/partner-logo/logo-8.png' },
];

function Sponsors() {
  return (
    <section className="sponsors">
      <div className="sponsors-logos">
        {sponsors.map((sponsor, index) => (
          <div key={index} className="sponsor">
            <img
              src={sponsor.logo}
              alt={sponsor.name}
              className="sponsor-logo small"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Sponsors;
